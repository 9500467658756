import { Routes, RouterModule } from '@angular/router';
import { ClaimsComponent } from './components/claims.component';
import { CustomerComponent } from './components/customer/customer.component'
import { ClaimsListComponent } from './components/claims-list/claims-list.component';
import { WarrantyComponent } from './components/warranty/warranty.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { DetailComponent } from './components/detail/detail.component';
import { RepairCentreComponent } from './components/repair-centre/repair-centre.component';
import { RoleGuard } from 'src-private/app/guards/role.guard';
import { PermissionGuard } from 'src-private/app/guards/permission.guard';
import { claimIdResloverService } from './resolver service/claimId-reslover.service';
import { ApprovalComponent } from './components/approval/approval.component';
import { AuditComponent } from './components/audit/audit.component';

const dealerRoutes: Routes = [
  {
    path: 'claims',
    component: ClaimsComponent,
    resolve: { claimId: claimIdResloverService },
    children: [
      {
        path: '',
        component: ClaimsListComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: ':enterpriseId/customer/:id',
        component: CustomerComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: 'customer/:id',
        component: CustomerComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
      },
      {
        path: ':enterpriseId/warranty/:id',
        component: WarrantyComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: 'warranty/:id',
        component: WarrantyComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
      },
      {
        path: ':enterpriseId/maintenance',
        component: MaintenanceComponent,
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: ':enterpriseId/detail',
        component: DetailComponent,
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: ':enterpriseId/repair-centre/:id',
        component: RepairCentreComponent,
        pathMatch: 'full',
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: ':enterpriseId/approval',
        component: ApprovalComponent,
        canActivate: [PermissionGuard],
        data: { permission: ['ClaimsManagement_Approve'] },
        resolve: { claimId: claimIdResloverService }
      },
      {
        path: ':enterpriseId/audit',
        component: AuditComponent,
        canActivate: [RoleGuard],
        data: { role: ['GlobalWarrantyGeneralAuditor', 'GlobalWarrantyInsurerAuditor'] },
        resolve: { claimId: claimIdResloverService }
      },
    ]
  },
  {
    path: 'customer/:id',
    component: CustomerComponent,
    pathMatch: 'full',
    canActivate: [RoleGuard],
    data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
  },
  {
    path: 'warranty/:id',
    component: WarrantyComponent,
    pathMatch: 'full',
    canActivate: [RoleGuard],
    data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    pathMatch: 'full',
    canActivate: [RoleGuard],
    data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
  },
  {
    path: 'repair-centre',
    component: RepairCentreComponent,
    canActivate: [RoleGuard],
    data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
  },
  {
    path: 'repair-centre/:id',
    component: RepairCentreComponent,
    canActivate: [RoleGuard],
    data: { role: ['GlobalWarrantyDealerServices-Claims', 'GlobalWarrantyClaimsAdjuster', 'GlobalWarrantyClaimsManager', 'GlobalWarrantyAssistantClaimsManager', 'GlobalWarrantyAccountant'] }
  }
];

export const dealersRouting = RouterModule.forChild(dealerRoutes);
