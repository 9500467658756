import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Params,
  Router
} from "@angular/router";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

/*-- Interfaces --*/
import {IEazeeTrakFile} from "./areas/eazeetrak/interfaces/eazeetrakfile.interface";
import {Sidebar as SidebarLocation} from "../app/enums/sidebar.enum";

/*-- Services --*/
import {AccountService} from "./areas/account/services/account.service";
import {AvatarService} from "./services/avatar.service";
import {EazeeTrakService} from "./areas/eazeetrak/services/eazeetrak.service";
import {WindowRef} from "./services/window.service";
import {NotificationService} from "./services/notification.service";
import {SearchResultService} from "./services/search-result.service";
import {MapService} from "./areas/claims/services/map.service";

/*-- Third Party --*/
import {Sidebar} from "ng-sidebar";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {faBell, faPlus, faSearch, faSun, faTimes,} from "@fortawesome/free-solid-svg-icons";
import {DynamicComponentService} from "./services/dynamic-component.service";
import {QuickAddComponent} from "./areas/actionbar/quick-add/quick-add.component";
import {OmniSearchComponent} from "./areas/actionbar/omni-search/omni-search.component";
import {NotificationComponent} from "./areas/actionbar/notification/notification.component";
import {NotificationSocketService} from "./services/notification-socket.service";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "src-private/environments/environment";
import {ClaimsService} from "./areas/claims/services/claims.service";
import {WarrantyService} from "./areas/claims/services/warranty.service";
import {EventsService} from "./services/events.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  @ViewChild("dynamicContainerTop", {
    read: ViewContainerRef,
    static: true
  })
  dynamicComponentTop: ViewContainerRef;
  @ViewChild("dynamicContainerRight", {read: ViewContainerRef, static: true})
  dynamicComponentRight: ViewContainerRef;
  @ViewChild("dynamicContainerBottom", {read: ViewContainerRef, static: true})
  dynamicComponentBottom: ViewContainerRef;
  @ViewChild("sidebarTop", {read: Sidebar})
  sidebarTop: Sidebar;
  @ViewChild("sidebarRight", {static: true}) sidebarRight: Sidebar;
  @ViewChild("sidebarBottom", {static: true}) sidebarBottom: Sidebar;

  notificationRefreshListener: EventEmitter<string> = new EventEmitter<string>();

  faBell = faBell;
  faPlus = faPlus;
  faTimes = faTimes;
  faSearch = faSearch;
  faSun = faSun;

  private eazeeTrakFile: IEazeeTrakFile;
  public isAdjuster: boolean;

  isAuthenticated = false;
  sidebarBottomOpened = false;
  sideBarNavigationMode: string;
  sidebarNavigationOpened: boolean;
  sidebarRightOpened = false;
  sidebarTopOpened = false;
  title = "app";
  userFullName: string;
  userId: string;
  visualIndicatorText: string;
  searchTerm: string = "";
  FilteredOption: string = "0";
  notificationsExist: boolean = false;
  isLoading = false;
  lightTheme = false;
  appVersion = environment.appVersion;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === '/') {
      // Prevent the Save dialog to open
      event.preventDefault();
      this.openOmniSearch();
    }
  }

  @HostListener('document:keydown.alt.shift.c', ['$event'])
  navigateClaims(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/']));
  }

  @HostListener('document:keydown.alt.shift.d', ['$event'])
  navigateDealers(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['dealers/']));
  }

  @HostListener('document:keydown.alt.shift.r', ['$event'])
  navigateReports(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['reports/']));
  }

  @HostListener('document:keydown.alt.shift.?', ['$event'])
  openSearch(event: KeyboardEvent) {
    event.preventDefault();
    this.openOmniSearch();
  }

  @HostListener('document:keydown.code.alt.shift.Equal', ['$event'])
  openAdd(event: KeyboardEvent) {
    event.preventDefault();
    this.openQuickAdd();
  }
  
  @HostListener('document:keydown.meta.shift.option.c', ['$event'])
  navigateClaimsApple(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['claims/']));
  }

  @HostListener('document:keydown.meta.shift.option.d', ['$event'])
  navigateDealersApple(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['dealers/']));
  }

  @HostListener('document:keydown.meta.shift.option.r', ['$event'])
  navigateReportsApple(event: KeyboardEvent) {
    event.preventDefault();
    this.router.navigateByUrl('/').then(()=>this.router.navigate(['reports/']));
  }

  @HostListener('document:keydown.meta.shift.option.?', ['$event'])
  openSearchApple(event: KeyboardEvent) {
    event.preventDefault();
    this.openOmniSearch();
  }

  @HostListener('document:keydown.code.meta.shift.option.Equal', ['$event'])
  openAddApple(event: KeyboardEvent) {
    event.preventDefault();
    this.openQuickAdd();
  }

  constructor(
    private accountService: AccountService,
    private avatarService: AvatarService,
    public dialog: MatDialog,
    private eazeeTrakService: EazeeTrakService,
    private notificationService: NotificationService,
    private searchResultService: SearchResultService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private mapService: MapService,
    private dynamicComponentService: DynamicComponentService,
    private notificationSocketService: NotificationSocketService,
    private oidcSecurityService: OidcSecurityService,
    private claimService: ClaimsService,
    private warrantyService: WarrantyService,
    private eventsService: EventsService
  ) {
    this.accountService.ready.subscribe((ready) => {

      if (!ready) {
        return;
      }

      this.userFullName = this.accountService.getUserName();
      this.userId = this.accountService.getUserId();
      this.isAuthenticated = true;
      this.isAdjuster = this.accountService.isAdjuster() || this.accountService.isClaimsManager() || this.accountService.isAssistantClaimsManager();

      this.handleNotifications();

      setTimeout(() => {
        this.handleEazeeTrakRedirect();
      });

      this.mapService.setupMapScript(this.elementRef).then(() => {
        // console.log("GOOGLE MAPS LOADED");
      });

      this.notificationSocketService.startNotificationSocket();

      this.notificationSocketService.refreshRequested.subscribe(() => {
        this.handleNotifications();
      });

    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.blockUI.start();
      }
      if (val instanceof NavigationEnd || val instanceof NavigationCancel || val instanceof NavigationError) {
        this.blockUI.stop();
      }
    });
  }

  //#region - Lifecycle
  ngOnInit() {
    this.lightTheme = (localStorage.getItem('e2theme') === 'dark-theme' || localStorage.getItem('e2theme') === null);

    this.setTheme();
  }

  //#endregion

  //#region - Getters
  get avatarImageUrl() {
    return this.avatarService.getAvatarUrl(this.accountService.getUserName());
  }

  //#endregion

  //#region - Private Methods
  private handleEazeeTrakRedirect(): void {
    const eazeeTrakFileId: number = this.windowRef.nativeWindow.fileId;

    if (eazeeTrakFileId && eazeeTrakFileId !== 0) {
      this.retrieveEazeeTrakFile(eazeeTrakFileId).then(() => {
        const dealerId = this.eazeeTrakFile.vendorId;
        const param = {dealerId: dealerId};
        const queryParams: Params = {f: eazeeTrakFileId};

        this.router.navigate(["dealers", param], {
          queryParams: queryParams,
          skipLocationChange: true,
          replaceUrl: true
        }).then();

        this.windowRef.nativeWindow.fileId = null;
      });
    }
  }

  private handleNotifications(): void {
    this.notificationService.list(false).subscribe((data: []) => {
      this.notificationsExist = data.length != 0;
      this.cdr.detectChanges();
    });
  }

  //#endregion

  //#region - API Methods
  private retrieveEazeeTrakFile(eazeeTrakFileId: number): Promise<{}> {
    return new Promise((resolve) => {
      this.eazeeTrakService
        .eazeeTrakFileRetrieve(eazeeTrakFileId, true)
        .subscribe((response) => {
          this.eazeeTrakFile = response;
          resolve(null);
        });
    });
  }

  logout() {
    this.oidcSecurityService.logoff().subscribe();
  }

  public get getVisualIndicatorInfo(): boolean {

    let route = this.router.url;
    let params = route.substr(1).split('/');

    if (route.includes('warranty')) {
      let warrantyIdFull: string = ((this.warrantyService.Warranty?.prefix) + (this.warrantyService.Warranty?.enterpriseId == 0 ? this.warrantyService.Warranty?.id : this.warrantyService.Warranty?.enterpriseId))
      this.visualIndicatorText = 'Warranty' + (warrantyIdFull ? ' - ' + warrantyIdFull : '');
    } else if (route.includes('repair-centre')) {
      if (route.includes('claims')) {
        this.visualIndicatorText = 'Claims' + (params[1] ? ' - ' + params[1] : '');
      } else {
        this.visualIndicatorText = 'Repair Centre';
      }
    } else if (route.includes('customer')) {
      if (route.includes('claims')) {
        this.visualIndicatorText = 'Customer' + (params[3] ? ' - ' + params[3] : '');
      } else {
        this.visualIndicatorText = 'Customer' + (params[1] ? ' - ' + params[1] : '');
      }
    } else if (route.includes('claims') && params.length > 1) {
      this.visualIndicatorText = 'Claims' + (params[1] ? ' - ' + params[1] : '');
    } else {
      this.visualIndicatorText = '';
    }

    return this.visualIndicatorText != '';
  }

  openQuickAdd() {
    (<HTMLInputElement>document.getElementById('quickAdd')).blur();

    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      QuickAddComponent
    );

    instance.modalResponse.subscribe((data) => {
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);

      if (data) {
        this.eventsService.broadcast(data);
      }
    });
  }

  openOmniSearch() {
    (<HTMLInputElement>document.getElementById('omnisearch')).blur();
    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      OmniSearchComponent
    );

    instance.modalResponse.subscribe(() => {
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);
    });
  }

  openNotification() {
    (<HTMLInputElement>document.getElementById('notification')).blur();

    const sidebarLocation = SidebarLocation.Right;
    this.dynamicComponentService.open(this, sidebarLocation);
    this.dynamicComponentService.setRootViewContainerRef(
      this.dynamicComponentRight
    );

    let instance: any = this.dynamicComponentService.addDynamicComponent(
      NotificationComponent
    );
    instance.modalResponse.subscribe(() => {
      (<HTMLInputElement>document.getElementById('notification')).blur();
      this.dynamicComponentService.closeAndClear(this, sidebarLocation, true);
    });
  }

  public onNotificationPanelClose() {
    if (!this.isAuthenticated) {
      return;
    }
    this.notificationSocketService.notifyHubtoEmitRefreshNotice();
    this.handleNotifications();
  }

  setTheme() {
    const body = document.getElementsByTagName('body')[0];

    if (!this.lightTheme) {
      body.classList.remove('dark-theme');
      body.classList.add('light-theme');
      localStorage.setItem('e2theme', 'light-theme');
      this.lightTheme = true;
    } else {
      body.classList.remove('light-theme');
      body.classList.add('dark-theme');
      localStorage.setItem('e2theme', 'dark-theme');
      this.lightTheme = false;
    }
  }

  get isAuditor() : boolean {
    return this.accountService.isGeneralAuditor() || this.accountService.isInsurerAuditor();
  }

  get isDemo(): boolean {
    return !environment.production
  }

}
