import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';


@NgModule({
  imports: [AuthModule.forRoot({
    config: {
      // authority: 'https://localhost:5001',
      authority: 'https://sso.globalwarranty.net',
      redirectUrl: `${window.location.origin}/callback`,
      postLogoutRedirectUri: window.location.origin,
      clientId: 'js',
      scope: 'openid api1 profile offline_access',
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      silentRenewUrl: window.location.origin + '/silent-renew.html',
      renewTimeBeforeTokenExpiresInSeconds: 10,
      logLevel: LogLevel.Debug,
      secureRoutes: [
        'http://localhost:60448/',
        'https://uat.claims.api.globalwarranty.net/',
        'http://localhost:58018/',
        'https://uat.claims.e2.api.globalwarranty.net/',
        'https://uat.e2.api.globalwarranty.net/',
        'https://uat.claims.api.globalwarranty.net/',
        'https://uat.api.globalwarranty.net/',
        'https://claims.api.globalwarranty.net/',
        'https://e2.api.globalwarranty.net/',
        'https://api.globalwarranty.net/',
      ],

    }
  })],
  exports: [AuthModule],
})
export class AuthConfigModule {
}
