import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IDocument, IDocumentUploadResponse } from '../interfaces/document';

@Injectable({
  providedIn: 'root'
})
export class WarrantyDocumentService {
  constructor(private http: HttpClient) { }

  retrieveAll(warrantyId: number): Observable<IDocument[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('warrantyId', warrantyId.toString());

    return this.http.get<IDocument[]>(`${environment.resourceUrl}api/gwc/warranty/documents`, { params: httpParams });
  }

  add(document: IDocument): Observable<IDocumentUploadResponse> {
    let formData = new FormData();
    Object.keys(document).forEach(function (key) {
      formData.append(key, document[key]);
    });
    return this.http.post<IDocumentUploadResponse>(`${environment.resourceUrl}api/gwc/warranty/document`, formData);
  }

  remove(documentId: number): Observable<Object> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('documentId', documentId.toString());

    return this.http.delete(`${environment.resourceUrl}api/gwc/warranty/document`, { params: httpParams })
  }

  download(warrantyId: number, documentId: number): Observable<HttpResponse<Blob>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('warrantyId', warrantyId.toString());
    httpParams = httpParams.append('documentId', documentId.toString());

    return this.http.get<Blob>(`${environment.resourceUrl}api/gwc/warranty/document`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: httpParams
    });
  }
}
