import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppValidators } from 'src-private/app/validators/app-validators';
import { faSave, faWindowClose, faUpload, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { IDocument } from '../../interfaces/document';
import { ClaimsService } from '../../services/claims.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-document-add-dialog',
  templateUrl: './document-add-dialog.component.html',
  styleUrls: ['./document-add-dialog.component.scss']
})
export class DocumentAddDialogComponent implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  private regx: string = '^(jpg|JPG|jpeg|gif|GIF|doc|DOC|DOCX|docx|pdf|PDF|txt|TXT|XLS|xls|xlsx|XLSX|csv|CSV|png|PNG)$';

  public documentAddForm: UntypedFormGroup;
  public selectedFile: File[] = [];
  public faSave = faSave;
  public faUpload = faUpload;
  public faTrash = faTrash;
  public faWindowClose = faWindowClose;
  public document: IDocument[] = [];
  public isSubmitted:boolean = false;
  public files: any[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private claimsService: ClaimsService,
    @Inject(MAT_DIALOG_DATA)
    public originId: number,
    private dialogRef: MatDialogRef<DocumentAddDialogComponent>) { }

  ngOnInit() {
    this.documentAddForm = this.formBuilder.group({
      originId: [0],
      description: [''],
      createdDate: [''],
      documents : ['']
    });

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    })
  }

  onFileChange(pFileList: File[]){
    let isNotAllowed = false
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    this.files.forEach(file => {
      
      if(!this.selectedFile.find(f => f.name == file.name)) {
        this.selectedFile.push(file);
      }
      if(!isNotAllowed && !file.name.split('.')[1].match(this.regx)) {
        this.documentAddForm.get('documents').setErrors({ pattern : true})
      }
      this.documentAddForm.get('documents').setErrors({ required : false });
      
    });
    
  }

  public getImageUrl(fileName) {
    return this.claimsService.getDocumentTypeUrl(fileName.split('.')[1]);
  }

  deleteFile(f){
    this.selectedFile = this.selectedFile.filter(function(w){ return w.name != f.name });
  }

  deleteFromArray(index) {
    this.files.splice(index, 1);
  }

  cancel() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addDocument() {
    if(this.selectedFile.length == 0) {
      this.documentAddForm.get('documents').setErrors({ required : false });
    }
    this.isSubmitted = true;
    if(this.selectedFile){
      
      this.documentAddForm.patchValue({ originId: this.originId });
     
      this.documentAddForm.patchValue({ description: this.documentAddForm.value.description, });
      this.documentAddForm.patchValue({ createdDate: (new Date()).toISOString() });

      this.selectedFile.forEach((file, index) => {
        this.documentAddForm.addControl(index.toString(), new FormControl(file) );
      })
    } 

    if (AppValidators.validateAll(this.documentAddForm) || this.selectedFile.length > 1) {

      this.onSubmit.emit({ data: this.documentAddForm.value });
    }
  }
}
