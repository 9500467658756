<form [formGroup]="form">
  <div class="form-row">

    <div class="col-sm-4 col-md-4 col-lg-1">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="warrantyid-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="warrantyid-addon"
                   aria-label="WarrantyId"
                   class="form-control disabled-transparent-background"
                   placeholder="Warranty ID"
                   type="text"
                   formControlName="WarrantyIdFull"
                   (keydown.esc)="onWarrantyIdTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="firstname-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="firstname-addon"
                   aria-label="FirstName"
                   class="form-control disabled-transparent-background"
                   placeholder="First Name"
                   type="text"
                   formControlName="customerFirstname"
                   (keydown.esc)="onFirstNameTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="lastname-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="lastname-addon"
                   aria-label="LastName"
                   class="form-control disabled-transparent-background"
                   placeholder="Last Name"
                   type="text"
                   formControlName="customerLastname"
                   (keydown.esc)="onLastNameTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="vin-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="vin-addon"
                   aria-label="VIN"
                   class="form-control disabled-transparent-background"
                   placeholder="VIN"
                   type="text"
                   formControlName="vin"
                   (keydown.esc)="onVinTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2" [hidden]="optionsHidden">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="dealer-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="dealer-addon"
                   aria-label="Dealer"
                   class="form-control disabled-transparent-background"
                   placeholder="Dealer"
                   type="text"
                   formControlName="dealer"
                   (keydown.esc)="onDealerTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2" [hidden]="optionsHidden">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="repair-center-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="repair-center-addon"
                   aria-label="RepairCenter"
                   class="form-control disabled-transparent-background"
                   placeholder="Repair Center"
                   type="text"
                   formControlName="repairCenter"
                   (keydown.esc)="onRepairCenterTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-1">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="claimid-addon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="claimid-addon"
                   aria-label="ClaimId"
                   class="form-control disabled-transparent-background"
                   placeholder="Claim ID"
                   type="number"
                   formControlName="claimId"
                   (keydown.esc)="onClaimIdTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-4 col-md-4 col-lg-2" [hidden]="optionsHidden">
      <div class="header-field-outer">
        <div class="header-field-inner">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text pr-0 search-filter" id="assignedAdjuster-addon"><fa-icon
                [icon]="faSearch"></fa-icon></span>
            </div>
            <input aria-describedby="assignedAdjuster-addon"
                   aria-label="AssignedAdjuster"
                   class="form-control disabled-transparent-background"
                   placeholder="Assigned Adjuster"
                   type="text"
                   formControlName="assignedAdjuster"
                   (keydown.esc)="onAssignedAdjusterTextEscKeyDown()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table claim-list-table">
  <thead class="th-white-text">
  <tr>
    <th>Warranty ID</th>
    <th>First Name</th>
    <th>Last Name</th>
    <th>VIN</th>
    <th>Dealer</th>
    <th>Repair Centre</th>
    <th>Claim ID</th>
    <th>Claim Date</th>
    <th>Assigned Adjuster</th>
    <th>Status</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="isLoading">
    <td align="center" class="td-loading-spinner text-center" colspan="10">
      <mat-spinner diameter="25"></mat-spinner>
    </td>
  </tr>
  <ng-container *ngIf="!isLoading">
    <tr *ngFor="let item of claims" [ngClass]="checkDate(item.claimsDateEntered)">
      <td class="text-left" >
        <div *ngIf="optionsHidden">{{item.warrantyIdFull}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'warranty', item.warrantyId]">{{item.warrantyIdFull}}</a>
      </td>
      <td>
        <div *ngIf="optionsHidden; else linkHidden">{{item.customerFirstname}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'customer', item.customerId]">{{item.customerFirstname}}</a>
      </td>
      <td>
        <div *ngIf="optionsHidden; else linkHidden">{{item.customerLastname}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'customer', item.customerId]">{{item.customerLastname}}</a>
      </td>
      <td class="text-left col-vin-width">
        <div *ngIf="optionsHidden; else linkHidden">{{item.warrantyVIN}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'warranty', item.warrantyId]">{{item.warrantyVIN}}</a>
      </td>
      <td class="col-sm-2">
        <div *ngIf="optionsHidden; else linkHidden">{{item.vendorName}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" skipLocationChange replaceUrl [routerLink]="['/dealers', { dealerId: item.vendorId }, 'dealer-info']">{{item.vendorName}}</a>
      </td>
      <td class="col-sm-2">
        <div *ngIf="optionsHidden; else linkHidden">{{item.repairCenterName}}</div>
        <a *ngIf="!optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'repair-centre', item.claimsRepairCenterId ? item.claimsRepairCenterId : {}]">{{item.repairCenterName}}</a>
      </td>
      <td align="center"  [colSpan]="item.id == null || item.id == 0? 4 : 0 ">
        <a *ngIf="item.id != null && item.id > 0 && !optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'detail']">{{item.enterpriseId}}</a>
        <a *ngIf="item.id != null && item.id > 0 && optionsHidden" class="claim-route-link" replaceUrl [routerLink]="['/claims', item.enterpriseId, 'audit']">{{item.enterpriseId}}</a>
        <button *ngIf="(item.id == null || item.id == 0) && item.warrantyStatus == 3 && isAdjuster" class="btn custom-button" type="button" (click)="addClaim(item)">
        <fa-icon [icon]="faPlus"></fa-icon> Add Claim
      </button></td>
      <td *ngIf="item.id != null && item.id > 0" >{{item.claimsDateEntered | date: 'MMM d, yyyy'}}</td>
      <td *ngIf="item.id != null && item.id > 0" >{{item.claimsOwner}}</td>
      <td *ngIf="item.id != null && item.id > 0" >{{item.claimsStatus}}</td>
    </tr>
    <tr *ngIf="claims?.length == 0">
      <td colspan="10" class="no-data-available">No data!</td>
    </tr>
  </ng-container>
  </tbody>
</table>
<div class="container text-center mb-1" *ngIf="claims?.length">
  <a class="font-weight-bold download-csv-link" (click)="generateCSV()">Download as CSV</a>
</div>
