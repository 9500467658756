import {Routes, RouterModule} from '@angular/router';

/*-- Components --*/
import {EazeetrakComponent} from './areas/eazeetrak/components/eazeetrak.component';
import {MigrationComponent} from './areas/migration/components/migration.component';
import {ReportsComponent} from './areas/reports/components/reports.component';
import {DealersComponent} from './areas/dealers/components/dealers.component';

/*-- Guards --*/
import {
  IncompleteRegistrationComponent
} from './areas/dealers/components/bonus-bucks/incomplete-registration/incomplete-registration.component';
import {CallbackComponent} from "./callback/callback.component";
import {UnauthorizedComponent} from "./unauthorized/unauthorized.component";
import {LogoutComponent} from "./logout/logout.component";
import {AutoLoginPartialRoutesGuard} from "angular-auth-oidc-client";

const appRoutes: Routes = [
  {path: '', redirectTo: 'eazeetrak', pathMatch: 'full'},
  {path: 'eazeetrak', component: EazeetrakComponent, pathMatch: 'full', canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'migration', component: MigrationComponent, pathMatch: 'full', canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'reports', component: ReportsComponent, pathMatch: 'full', canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'dealers', component: DealersComponent, pathMatch: 'full', canActivate: [AutoLoginPartialRoutesGuard]},
  {path: 'callback', component: CallbackComponent, pathMatch: 'full'},
  {path: 'unauthorized', component: UnauthorizedComponent, pathMatch: 'full'},
  {path: 'logout', component: LogoutComponent, pathMatch: 'full'},

  // { path: 'claims/claims-list', component: ClaimsListComponent, pathMatch: 'full', canActivate: [AutoLoginAllRoutesGuard] },
  {
    path: 'complete-registration/:guid',
    component: IncompleteRegistrationComponent,
  },
];

export const routing = RouterModule.forRoot(appRoutes, {});
