export interface IDocument {
  documentId?: number;
  originId: number;
  fileName: string;
  fileType: string
  description: string;
  createdBy?: string;
  createdDate: string;
  fileData: File;
  createdOn?: string;
  documentTypeImage?: string;
}

export interface IWarrantyDocument extends IDocument{
  warrantyId?: number;
}

export interface IClaimDocument extends IDocument{
  claimsId?: number;
}

export interface IDocumentUploadResponse {
  conflicts: IDocument[];
  uploaded: IDocument[];
}

export enum DocumentTypes {
  Claim = 1,
  Warranty = 2,
  Customer = 3,
  Dealer = 4
}
