import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src-private/environments/environment';
import { IClaim } from '../interfaces/claim';
import { Claim } from '../models/claim-model';
import { ClaimsRetrieve } from '../models/claims-retrieve.model';
import { IClaimSource } from '../interfaces/claim-source';


@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  private selectedClaim:Claim
  private isDialogShown: boolean;

  constructor(private http: HttpClient) { }

  retrieveAll(params: ClaimsRetrieve): Observable<IClaim[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    return this.http.get<IClaim[]>(`${environment.resourceUrl}api/gwc/claims`, { params: httpParams });
  }

  retrieveAllAuditor(params: ClaimsRetrieve): Observable<IClaim[]> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    return this.http.get<IClaim[]>(`${environment.resourceUrl}api/gwc/claimsAuditor`, { params: httpParams });
  }

  retrieve(claimId: number): Observable<IClaim> {
    return this.http.get<IClaim>(`${environment.resourceUrl}api/gwc/claim`, {
      params: new HttpParams().set('claimId', claimId.toString())
    });
  }

  claimsAdd(claim: IClaim): Observable<number> {
    return this.http.post<number>(environment.resourceUrl + 'api/gwc/claim', claim);
  }

  claimsUpdate(claim: IClaim): Observable<IClaim> {
    return this.http.put<IClaim>(environment.resourceUrl + 'api/gwc/claim', claim);
  }

  sourceUpdate(claimId: number, sourceId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('claimId', claimId);
    httpParams = httpParams.append('sourceId',  parseInt(sourceId));
  }
  
  retrieveClaimId(enterpriseId: number):Observable<number>{
    return this.http.get<number>(`${environment.resourceUrl}api/gwc/claim`, {
      params: new HttpParams().set('enterpriseId', enterpriseId.toString())
    });
  }

  //Used to share Selected Claim throughtout Application
  setSelectedClaim(claim:Claim){
    this.selectedClaim = claim
  }

  get getSelectedClaim():Claim{
    return this.selectedClaim
  }

  set setDialogState(value: boolean){
    this.isDialogShown = value
  }

  get getDialogShownState(): boolean{
    return this.isDialogShown
  }

  getDocumentTypeUrl(fileType:string):string {
    let imageUrl = '/assets/images/file.png';
    let imageUrlSet = new Map<string, string>();

    imageUrlSet.set('^(jpg|JPG|jpeg|gif|GIF|png|PNG)$', '/assets/images/image.png');
    imageUrlSet.set('^(doc|DOC|DOCX|docx)$', '/assets/images/word.png');
    imageUrlSet.set('^(pdf|PDF)$', '/assets/images/pdf.png');
    imageUrlSet.set('^(XLS|xls|xlsx|XLSX|csv|CSV)$', '/assets/images/excel.png');

    imageUrlSet.forEach((value:string, regex:string)=>{
      if(fileType.match(regex)){
        imageUrl = value;
      }
    })

    return imageUrl;
  }

  getClaimSources(sourceId: number): Observable<IClaimSource[]> {
    return this.http.get<IClaimSource[]>(`${environment.resourceUrl}api/gwc/claimSources`, {
      params: new HttpParams().set('sourceId', sourceId)
    });
  }

  claimSourceUpdate(claimId: number, sourceId: number): Observable<number> {
    return this.http.put<number>(environment.resourceUrl + 'api/gwc/claimSourceUpdate', { claimId, sourceId });
  }
}
