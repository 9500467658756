import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { IClaim } from '../../interfaces/claim';
import { ClaimsService } from '../../services/claims.service';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ClaimDocumentsComponent } from "../claim-documents/claim-documents.component";
import { Title } from "@angular/platform-browser";
import { CellBuilder } from "src-private/app/shared/table-adapter/cell-builder/cell-builder";
import { CellActions, CellType } from "src-private/app/shared/table-adapter/cell-builder/cell-type";
import { TableAdapterComponent } from "src-private/app/shared/table-adapter/table-adapter.component";
import { IWarrantyOption } from "../../interfaces/warranty.interface";
import { WarrantyService } from "../../services/warranty.service";
import { ILoginUser } from "../../interfaces/loginuser.interface";
import { AccountService } from "src-private/app/areas/account/services/account.service";
import { LoginUserService } from "../../services/loginuser.service";
import { environment } from "src-private/environments/environment";
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit, OnDestroy {
  public claim: IClaim;
  public claimId: number;

  public partsTableColumns: CellBuilder[] = [
    new CellBuilder("Type", "indicator", CellType.text),
    new CellBuilder("RC Name", "repairCentreName", CellType.text),
    new CellBuilder("RC ID", "repairCentreId", CellType.text),
    new CellBuilder("Part Group", "partGroupName", CellType.number),
    new CellBuilder("Part Description", "partDescription", CellType.text),
    new CellBuilder("Quantity", "quantity", CellType.number),
    new CellBuilder("GoodWill", "goodwillState", CellType.text),
    new CellBuilder("Total before Tax", "rowTotal", CellType.currency),
    new CellBuilder("GST", "gstAmount", CellType.currency),
    new CellBuilder("PST", "pstAmount", CellType.currency),
    new CellBuilder("HST", "hstAmount", CellType.currency),
    new CellBuilder("Total after Tax", "rowTotalWithTax", CellType.currency),
  ];

  @ViewChild("warrantyOptionsTable")
  warrantyOptionsTable: TableAdapterComponent<IWarrantyOption>;
  public warrantyOptionsTableColumns: CellBuilder[] = [
    new CellBuilder("ID", "id", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Price", "amount", CellType.currency),
  ];

  public warrantyNotesTableColumns: CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.datetime, 'col-2 col-sm-2'),
    new CellBuilder("Adjuster", "adjusterName", CellType.text),
    new CellBuilder("Note", "note", CellType.text),
  ];

  public warrantyDocumentsTableColumns: CellBuilder[] = [
    new CellBuilder(" ", "documentTypeImage", CellType.image, 'w-06'),
    new CellBuilder("Filename", "fileName", CellType.text),
    new CellBuilder("Description", "description", CellType.text),
    new CellBuilder("Created Date", "createdOn", CellType.text),
    new CellBuilder("Uploaded by", "createdBy", CellType.text, "col-sm-3"),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Download | CellActions.Remove | CellActions.Move),
  ];

  public repairCentreNotesTableColumns :CellBuilder[] = [
    new CellBuilder("Created Date", "createdDate", CellType.date, 'col-3 col-sm-3'),
    new CellBuilder("Adjuster", "adjusterName", CellType.text, 'col-2 col-sm-2'),
    new CellBuilder("Note", "note", CellType.text)
  ]

  public maintScheduleTableColumns: CellBuilder[] = [
    new CellBuilder("Date of Maintenance", "date", CellType.date),
    new CellBuilder("KMs at this Date", "kms", CellType.number),
    new CellBuilder("Maintenance Term", "term", CellType.text),
    new CellBuilder("Max Term KMs", "maxTermKms", CellType.text),
    new CellBuilder("User Entering Maintenance Schedule", "user", CellType.text),
    new CellBuilder("Due Date", "dueDate", CellType.date),
    new CellBuilder("Max KMs", "maxKms", CellType.number),
    new CellBuilder("Action", "action", CellType.actions, '', CellActions.Remove)
  ];

  @ViewChild(ClaimDocumentsComponent) claimDocuments: ClaimDocumentsComponent;

  private readonly ngUnsubscribe$: Subject<boolean> = new Subject();
 @BlockUI() blockUI: NgBlockUI;

  constructor(
    private activatedRoute: ActivatedRoute,
    private claimsService: ClaimsService,
    private warrantyService: WarrantyService,
    private accountService: AccountService,
    private loginUserService: LoginUserService,
    private router: Router,
    private titleService: Title,
    public dialog: MatDialog,
    ) { 
  }

  ngOnInit(): void {
  	this.titleService.setTitle("Audit");
    this.claim = this.claimsService.getSelectedClaim;
    if (!this.claim) {
      let claimId = this.activatedRoute.snapshot.data['claimId']
      this.claimsService.retrieve(claimId)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe({
          next: claim => {
            if (claim) {
              this.claim = claim;

              this.warrantyService.retrieve(claim.warrantyId)
              .pipe(takeUntil(this.ngUnsubscribe$))
              .subscribe({
                next: warranty => {
                  if (warranty) {
                    let email = this.accountService.getEmail();
                    if (email) {
                      if (email.includes(environment.insurerEmail) && (new Date(warranty.effectiveDate).getTime() < environment.insurerDateCutoff.getTime())) {
                        let route =  '/unauthorized';
                        this.router.navigateByUrl('/', {
                          skipLocationChange: false 
                        }).then(() => this.router.navigate([route])); 
                      }
                    }
                  }
                }
              });

            }
          }
        });
    }
  }

  onDocumentAdded(documentAdded: boolean) {
    if(documentAdded) {
      this.claimDocuments.refreshDocuments();
    }
  } 

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
  }

  partsListCanRenderActions = (actions: CellActions): boolean => {
    return false;
  }

  isClaimClosed(): boolean {
    return this.claim.claimsStatus === 'Closed';
  }
}
